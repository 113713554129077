const initProjectsSlider = async function( $block ) {
	const { Swiper } = await import( "swiper" );
	await import( "swiper/css/bundle" );
	const { Navigation, Pagination } = await import( "swiper/modules" );

	const $slider = $block.find( ".projects-slider" );

	if ( !$slider.length ) { return false; }

	let projectsSlider = [];
	$( $slider ).each( function( index ) {
		let projectsSliderItem = $( this );
		projectsSliderItem.addClass( "projectImages-" + index );
		projectsSlider[index] = new Swiper( ".projectImages-" + index, {
			modules: [Navigation, Pagination],
			slidesPerView: 1,
			speed: 1000,
			spaceBetween: 20,
			pagination: {
				el: ".projects__slider--pagination-" + index,
				type: "bullets",
				clickable: true,
			},
			navigation: {
				nextEl: ".projects__slider--next-" + index,
				prevEl: ".projects__slider--prev-" + index,
			},
			breakpoints: {
				940: {slidesPerView: 3},
				600: {slidesPerView: 2},
			}
		} );
	} );
};
 
themeUtils.loadBlock( initProjectsSlider, "projects", ".projects" );

$( window ).on( "load", function() {
	// tabs
	$( ".projects__tabs" ).on( "click", "li:not(.active)", function() { 
		$( this )
			.addClass( "active" ).siblings().removeClass( "active" )
			.closest( ".projects" ).find( ".projects__list--tab" ).removeClass( "active" ).eq( $( this ).index() ).addClass( "active" );
	} );
} );
